.rbc-day-bg:hover {
    cursor: pointer;
    background-color: #dbdbdb;
}

.rbc-date-cell a {
    background-color: #f0f0f0;
}

.rbc-date-cell a:hover {
    width: 100%;
    background-color: #dbdbdb;
}

.rbc-month-view, .rbc-btn-group {
    background: white;
}
