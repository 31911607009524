/* [class^=MuiMenu-paper]:before, [class*=MuiMenu-paper]:before {
    content: ' ';
    position: absolute;
    width: 0;
    height: 0;
    left: 100%;
    top: 6.8%;
    border: 9px solid;
    border-color: #dbdbdb50 transparent transparent #dbdbdb50;
}

[class^=MuiMenu-paper]:after, [class*=MuiMenu-paper]:after {
    content: ' ';
    position: absolute;
    width: 0;
    height: 0;
    left: 100%;
    top: 7%;
    border: 6px solid;
    border-color: white transparent transparent white;
} */

[class^=MuiModal-root], [class*=MuiModal-root] {
    z-index: 99999 !important;
}

[class^=MuiTooltip-popper], [class*=MuiTooltip-popper] {
    z-index: 999999 !important;
}