/* [class^=MuiMenu-paper]:before, [class*=MuiMenu-paper]:before {
    content: ' ';
    position: absolute;
    width: 0;
    height: 0;
    left: 100%;
    top: 6.8%;
    border: 9px solid;
    border-color: #dbdbdb50 transparent transparent #dbdbdb50;
}

[class^=MuiMenu-paper]:after, [class*=MuiMenu-paper]:after {
    content: ' ';
    position: absolute;
    width: 0;
    height: 0;
    left: 100%;
    top: 7%;
    border: 6px solid;
    border-color: white transparent transparent white;
} */

[class^=MuiModal-root], [class*=MuiModal-root] {
    z-index: 99999 !important;
}

[class^=MuiTooltip-popper], [class*=MuiTooltip-popper] {
    z-index: 999999 !important;
}
.no-print {
    padding: 0;
    margin: 0;
}

@media print {
    .no-print{
        display: none!important
    }
}
.only-print {
    padding: 0;
    margin: 0;
}

@media screen {
    .only-print {
        display: none!important
    }
}
.rbc-day-bg:hover {
    cursor: pointer;
    background-color: #dbdbdb;
}

.rbc-date-cell a {
    background-color: #f0f0f0;
}

.rbc-date-cell a:hover {
    width: 100%;
    background-color: #dbdbdb;
}

.rbc-month-view, .rbc-btn-group {
    background: white;
}

.react-autosuggest__container {
    position: relative;
}

.react-autosuggest__input {
    height: 30px;
    padding: 10px 20px;
    font-weight: 300;
    font-size: 16px;
    border: 1px solid #aaa;
    border-radius: 4px;
}

.react-autosuggest__input--focused {
    outline: none;
}

.react-autosuggest__input--open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
    display: none;
}

.react-autosuggest__suggestions-container--open {
    display: block;
    position: absolute;
    top: 51px;
    border: 1px solid #aaa;
    background-color: #fff;
    font-weight: 300;
    font-size: 16px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    z-index: 2;
}

.react-autosuggest__suggestions-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.react-autosuggest__suggestion {
    cursor: pointer;
    padding: 10px 20px;
}

.react-autosuggest__suggestion--highlighted {
    background-color: #ddd;
}
div[role=presentation] {
    z-index: 999999999999999 !important;
}

.MuiTooltip-popper {
    z-index: 9999999999999999 !important;
}

div[id*=menu-], div[id^=menu-] {
    z-index: 99999999999999999 !important;
}

div[class*=MUIDataTableFilter-gridListTile], div[class^=MUIDataTableFilter-gridListTile] {
    overflow: visible;
}
.scrollable-datepicker .rdrDefinedRangesWrapper {
    width: 0;
    height: 0;
    visibility: hidden;
}

.scrollable-datepicker .rdrCalendarWrapper {
    font-size: .6em;
}

.scrollable-datepicker .rdrDateDisplayWrapper {
    width: 97%;
}

.scrollable-datepicker .rdrMonthAndYearWrapper {
    width: 98%;
}
.rc-trigger-popup {
  position: absolute;
  left: -9999px;
  top: -9999px;
  z-index: 1050;
}
.rc-trigger-popup-hidden {
  display: none;
}
.rc-trigger-popup-zoom-enter,
.rc-trigger-popup-zoom-appear {
  opacity: 0;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-timing-function: cubic-bezier(0.18, 0.89, 0.32, 1.28);
          animation-timing-function: cubic-bezier(0.18, 0.89, 0.32, 1.28);
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.rc-trigger-popup-zoom-leave {
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-timing-function: cubic-bezier(0.6, -0.3, 0.74, 0.05);
          animation-timing-function: cubic-bezier(0.6, -0.3, 0.74, 0.05);
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.rc-trigger-popup-zoom-enter.rc-trigger-popup-zoom-enter-active,
.rc-trigger-popup-zoom-appear.rc-trigger-popup-zoom-appear-active {
  -webkit-animation-name: rcTriggerZoomIn;
          animation-name: rcTriggerZoomIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.rc-trigger-popup-zoom-leave.rc-trigger-popup-zoom-leave-active {
  -webkit-animation-name: rcTriggerZoomOut;
          animation-name: rcTriggerZoomOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
@-webkit-keyframes rcTriggerZoomIn {
  0% {
    opacity: 0;
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    -webkit-transform: scale(0, 0);
            transform: scale(0, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
  }
}
@keyframes rcTriggerZoomIn {
  0% {
    opacity: 0;
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    -webkit-transform: scale(0, 0);
            transform: scale(0, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
  }
}
@-webkit-keyframes rcTriggerZoomOut {
  0% {
    opacity: 1;
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
  }
  100% {
    opacity: 0;
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    -webkit-transform: scale(0, 0);
            transform: scale(0, 0);
  }
}
@keyframes rcTriggerZoomOut {
  0% {
    opacity: 1;
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
  }
  100% {
    opacity: 0;
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    -webkit-transform: scale(0, 0);
            transform: scale(0, 0);
  }
}
.rc-trigger-popup-mask {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: #373737;
  background-color: rgba(55, 55, 55, 0.6);
  height: 100%;
  filter: alpha(opacity=50);
  z-index: 1050;
}
.rc-trigger-popup-mask-hidden {
  display: none;
}
.rc-trigger-popup-fade-enter,
.rc-trigger-popup-fade-appear {
  opacity: 0;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-timing-function: cubic-bezier(0.55, 0, 0.55, 0.2);
          animation-timing-function: cubic-bezier(0.55, 0, 0.55, 0.2);
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.rc-trigger-popup-fade-leave {
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-timing-function: cubic-bezier(0.55, 0, 0.55, 0.2);
          animation-timing-function: cubic-bezier(0.55, 0, 0.55, 0.2);
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.rc-trigger-popup-fade-enter.rc-trigger-popup-fade-enter-active,
.rc-trigger-popup-fade-appear.rc-trigger-popup-fade-appear-active {
  -webkit-animation-name: rcTriggerMaskFadeIn;
          animation-name: rcTriggerMaskFadeIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.rc-trigger-popup-fade-leave.rc-trigger-popup-fade-leave-active {
  -webkit-animation-name: rcDialogFadeOut;
          animation-name: rcDialogFadeOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
@-webkit-keyframes rcTriggerMaskFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes rcTriggerMaskFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes rcDialogFadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes rcDialogFadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

