div[role=presentation] {
    z-index: 999999999999999 !important;
}

.MuiTooltip-popper {
    z-index: 9999999999999999 !important;
}

div[id*=menu-], div[id^=menu-] {
    z-index: 99999999999999999 !important;
}

div[class*=MUIDataTableFilter-gridListTile], div[class^=MUIDataTableFilter-gridListTile] {
    overflow: visible;
}