.scrollable-datepicker .rdrDefinedRangesWrapper {
    width: 0;
    height: 0;
    visibility: hidden;
}

.scrollable-datepicker .rdrCalendarWrapper {
    font-size: .6em;
}

.scrollable-datepicker .rdrDateDisplayWrapper {
    width: 97%;
}

.scrollable-datepicker .rdrMonthAndYearWrapper {
    width: 98%;
}